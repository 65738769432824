import YubinBango from "@tential/yubinbango/yubinbango-core2"

/**
 * 注意：
 * この定数はYubinBangoライブラリ、coreのREGIONデータに依存しています。
 * YubinBangoライブラリのアップデートがある場合は、コアデータが影響を受けないことを必ず確認してください。
 * このデータの欠落または変更により、アプリケーションの動作に問題が生じる可能性があります。
 * 現在の実装では、YubinBangoは積極的にメンテナンスされていないため、変更は少ないと思われますが、可能性としては存在します。
 * ライブラリのアップデート時には、常にcoreのREGIONデータの存在と一貫性を確認してください。
 */
export const PREFECTURES_FROM_YUBIN_BANGO = YubinBango.Core.REGION.filter((el) => el !== null)
