import { ProductAndSku } from "@tential/ec-gql-schema/types/cart"
import { PREFECTURES_FROM_YUBIN_BANGO } from "../const/address"

// 共通エラーメッセージ
const INPUT_EMPTY_ERROR_MESSAGE = "上記項目をご入力ください。"

interface ValidationResult {
  errorMessage: string
  isPassed: boolean
}

const _removeSpace = (_string: string): string => {
  // eslint-disable-next-line no-irregular-whitespace
  return _string.replace(/[ 　]/g, "")
}

export const nameValidation = (_name: string, _isAmazonPay?: boolean): ValidationResult => {
  const removedSpace = _removeSpace(_name)

  if (removedSpace.length <= 1) {
    let errorMessage = INPUT_EMPTY_ERROR_MESSAGE
    if (_isAmazonPay) errorMessage = INPUT_EMPTY_ERROR_MESSAGE
    return {
      errorMessage,
      isPassed: false,
    }
  } else {
    return {
      errorMessage: "",
      isPassed: true,
    }
  }
}

export const nameKanaValidation = (_nameKana: string): ValidationResult => {
  if (typeof _nameKana !== "string") {
    return {
      errorMessage: "",
      isPassed: false,
    }
  }
  const removedSpace = _removeSpace(_nameKana)

  if (!/^[ァ-ンヴー]*$/.test(removedSpace)) {
    return {
      errorMessage: "カタカナのみ有効です",
      isPassed: false,
    }
  } else if (removedSpace.length <= 1) {
    return {
      errorMessage: INPUT_EMPTY_ERROR_MESSAGE,
      isPassed: false,
    }
  } else {
    return {
      errorMessage: "",
      isPassed: true,
    }
  }
}

export const zipCodeValidation = (_zipCode: string): ValidationResult => {
  if (!/\d{7}/.test(_zipCode)) {
    return {
      errorMessage: "7桁の半角数字でご入力ください。",
      isPassed: false,
    }
  } else {
    return {
      errorMessage: "",
      isPassed: true,
    }
  }
}

export const zipCodeConfirmValidation = (_prefecture: string): ValidationResult => {
  if (!_prefecture) {
    return {
      errorMessage: "住所が存在しません。正しい郵便番号を入力して下さい。",
      isPassed: false,
    }
  } else {
    return {
      errorMessage: "",
      isPassed: true,
    }
  }
}

export const prefectureValidation = (_prefecture: string, _isAmazonPay?: boolean): ValidationResult => {
  if (_prefecture === "") {
    let errorMessage = "都道府県を選択してください"
    if (_isAmazonPay) errorMessage = "都道府県が存在しません"
    return {
      errorMessage,
      isPassed: false,
    }
  } else {
    return {
      errorMessage: "",
      isPassed: true,
    }
  }
}

export const addressLine1Validation = (_addressLine1: string, _isAmazonPay?: boolean): ValidationResult => {
  if (_addressLine1.length <= 1) {
    let errorMessage = INPUT_EMPTY_ERROR_MESSAGE
    if (_isAmazonPay) errorMessage = "文字が少なすぎます。"
    return {
      errorMessage,
      isPassed: false,
    }
  } else {
    return {
      errorMessage: "",
      isPassed: true,
    }
  }
}

export const addressLine2Validation = (_addressLine2: string, _isAmazonPay?: boolean): ValidationResult => {
  if (!_addressLine2) {
    let errorMessage = INPUT_EMPTY_ERROR_MESSAGE
    if (_isAmazonPay) errorMessage = "文字が少なすぎます。"
    return {
      errorMessage,
      isPassed: false,
    }
  } else {
    return {
      errorMessage: "",
      isPassed: true,
    }
  }
}

const containsNumberOrKanjiNumber = (str: string): boolean => {
  // 数字(半角・全角)または漢数字を探す正規表現
  const pattern = /[0-9０-９一二三四五六七八九]/
  return pattern.test(str)
}

export const addressValidation = (
  _zipCode: string,
  _combinedAddress: string,
  _isAmazonPay?: boolean,
): ValidationResult => {
  let errorMessage = ""
  let isPassed = true

  if (
    _combinedAddress.length <= 1 ||
    (!containsNumberOrKanjiNumber(_combinedAddress) && !_combinedAddress.includes("無番地"))
  ) {
    errorMessage = "住所が不完全です。正しく入力してください"
    if (_isAmazonPay) errorMessage = "番地または「無番地」を入力してください"
    isPassed = false
  } else if (PREFECTURES_FROM_YUBIN_BANGO.some((pref) => _combinedAddress.includes(pref))) {
    errorMessage = "都道府県が重複しています"
    isPassed = false
  }
  return {
    errorMessage,
    isPassed,
  }
}

export const telValidation = (_tel: string, _isAmazonPay?: boolean): ValidationResult => {
  if (!String(_tel).match(/^0\d{9,10}$/)) {
    let errorMessage = "0から始まる10桁または11桁の半角数字をご入力ください。"
    if (_isAmazonPay) errorMessage = "0から始まる10桁または11桁の半角数字をご入力ください。"
    return {
      errorMessage,
      isPassed: false,
    }
  } else {
    return {
      errorMessage: "",
      isPassed: true,
    }
  }
}

export const emailValidation = (email: string): ValidationResult => {
  const isPassed =
    /^[\w!#$%&'*+/=?^_` + "`" + `{|}~-]+(?:\.[\w!#$%&'*+/=?^_` + "`" + `{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[a-zA-Z0-9](?:[\w-]*[\w])?$/.test(
      email,
    )
  const errorMessage = isPassed ? "" : "正しいメールアドレスを入力してください"
  return {
    errorMessage,
    isPassed,
  }
}

export const passwordValidationFull = (_password: string): ValidationResult => {
  const validateResult = passwordValidation(_password)
  if (!validateResult.isPassed) return validateResult
  const validatePasswordCombination = passwordCombinationValidation(_password)
  if (!validatePasswordCombination.isPassed) return validatePasswordCombination
  return isSequentialOrRepeated(_password)
}

export const passwordValidation = (_password: string): ValidationResult => {
  if (!/^[a-zA-Z0-9!"#$%&''()\-^@[;:\],./|`{+*}<>?_]{8,24}$/i.test(_password)) {
    return {
      errorMessage: "8〜24文字の英数字記号のみを入力してください",
      isPassed: false,
    }
  } else {
    return {
      errorMessage: "",
      isPassed: true,
    }
  }
}

export const passwordCombinationValidation = (password: string): ValidationResult => {
  const regText = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9!"#$%&''()\-^@[;:\],./|`{+*}<>?_]{8,}$/
  const regExp = new RegExp(regText)
  if (!regExp.test(password)) {
    return {
      errorMessage: "半角英字、数字を組み合わせて8文字以上で入力してください",
      isPassed: false,
    }
  } else {
    return {
      errorMessage: "",
      isPassed: true,
    }
  }
}

export const isSequentialOrRepeated = (str: string): ValidationResult => {
  // Check for four or more of the same character in a row
  const regexRepeated = /(.)\1{3,}/
  if (regexRepeated.test(str)) {
    return {
      isPassed: false,
      errorMessage: "繰り返し同じ文字が4字以上使用されています",
    }
  }

  // Check for four or more sequential characters
  for (let i = 0; i < str.length - 3; i++) {
    const substr = str.substring(i, i + 4)
    const charCodes = substr.split("").map((char) => char.charCodeAt(0))

    // Check if all four character codes are sequential (either ascending or descending)
    if (charCodes[0] - charCodes[1] === 1 && charCodes[1] - charCodes[2] === 1 && charCodes[2] - charCodes[3] === 1) {
      return {
        isPassed: false,
        errorMessage: "4321のような連続した数字が使用されています",
      }
    } else if (
      charCodes[1] - charCodes[0] === 1 &&
      charCodes[2] - charCodes[1] === 1 &&
      charCodes[3] - charCodes[2] === 1
    ) {
      return {
        isPassed: false,
        errorMessage: "1234のような連続した数字が使用されています",
      }
    }
  }

  return {
    isPassed: true,
    errorMessage: "",
  }
}

/**
 * typeごとのバリデーションを行いメッセージを返す
 * @param type
 * @param value
 * @returns { errorMessage: string, isPassed: boolean }
 */
export const getValidationMessage = async ({
  type,
  value,
  value2,
  _isAmazonPay,
}: {
  type: string
  value: string
  value2?: string
  _isAmazonPay?: boolean
}): Promise<ValidationResult> => {
  if (type === "name" || type === "user_info.name" || type === "address_info.name")
    return nameValidation(value, _isAmazonPay)
  if (type.includes("name_kana")) return nameKanaValidation(value)
  if (type.includes("zip_code")) return zipCodeValidation(value)
  if (type.includes("zip_code_confirm")) return zipCodeConfirmValidation(value)
  if (type.includes("prefecture")) return prefectureValidation(value, _isAmazonPay)
  if (type.includes("address_line1")) return addressLine1Validation(value, _isAmazonPay)
  if (type.includes("address_line2")) return addressLine2Validation(value, _isAmazonPay)
  if (type.includes("address_combination")) return await addressValidation(value, value2 || "", _isAmazonPay)
  if (type.includes("tel")) return telValidation(value, _isAmazonPay)
  if (type.includes("email")) return emailValidation(value)
  if (type.includes("password")) return passwordValidationFull(value)
  if (type.includes("password_basic")) return passwordValidation(value)
  if (type.includes("password_combination")) return passwordCombinationValidation(value)

  return {
    isPassed: true,
    errorMessage: "",
  }
}

export const checkGiftItemLimit = (cartLine: ProductAndSku[], cartOnlyGift: boolean, quantity: number): boolean => {
  // カート内の単品専用ギフト商品の数量の合計を計算
  const totalGiftItemsQuantity = cartLine.reduce((total, item) => {
    return item.product.cart_only_gift ? total + item.quantity : total
  }, 0)

  // 追加しようとしている商品が単品専用ギフト商品の場合、数量を合計に加算
  const newTotalGiftItemsQuantity = cartOnlyGift ? totalGiftItemsQuantity + quantity : totalGiftItemsQuantity

  // ギフト商品の購入上限を超えているかチェック
  return newTotalGiftItemsQuantity > 3
}
